import { isSuperAdmin } from "../../../../../hooks/useUserInfo";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
 
const SidebarMenuMain = () => {
  interface ISideBarChildMenu {
    path: string;
    title: string;
    child: [];
    hasBullet: boolean;
  }
  interface ISideBarMenu {
    path: string;
    title: string;
    icon: any;
    fontIcon: string;
    child: ISideBarChildMenu[] | [];
    hasBullet: boolean;
    isSuperAdmin: boolean;
    isAdmin: boolean;
  }
  const sideBarMenus: ISideBarMenu[] = [
    {
      path: "/dashboard",
      icon: "abstract-1",
      title: "Dashboard",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/banner",
      icon: "picture",
      title: "Banner",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/category",
      icon: "abstract-3",
      title: "Category",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/squeez",
      icon: "abstract-22",
      title: "Squeez",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    // {
    //   path: "/booking",
    //   icon: "bookmark",
    //   title: "Booking",
    //   fontIcon: "bi-layers",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: true,
    // },
    {
      path: "/waitlist",
      icon: "time",
      title: "WaitList",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/app-user",
      icon: "user-edit",
      title: "App User",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/app-user/app-admin",
          title: "App Admin",
          child: [],
          hasBullet: true,
        },
        {
          path: "/app-user/app-customer",
          title: "App Customer",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/app-contactus",
      icon: "disconnect",
      title: "Contact Us",
      fontIcon: "bi-app-indicator",
      child: [
        {
          path: "/app-contactus/contactus",
          title: "Contact Us(M)",
          child: [],
          hasBullet: true,
        },
        {
          path: "/app-contactus/web-contactus",
          title: "Contact Us(W)",
          child: [],
          hasBullet: true,
        },
        {
          path: "/app-contactus/inquiry",
          title: "Inquiry",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/userPreference",
      icon: "setting-4",
      title: "User Preference",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/terms-conditions",
      icon: "address-book",
      title: "Terms & Conditions",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/privacy-policy",
      icon: "lock-2",
      title: "Privacy Policy",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/payment-terms-conditions",
      icon: "credit-cart",
      title: "Payment Terms & Conditions",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/payment-privacy",
      icon: "information",
      title: "Payment Privacy",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/about-app",
      icon: "information-2",
      title: "About App",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/faq",
      icon: "question-2",
      title: "FAQ",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    // {
    //   path: "/contactus",
    //   icon: "disconnect",
    //   title: "Contact Us",
    //   fontIcon: "bi-app-indicator",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    // },
    // {
    //   path: "/inquiry",
    //   icon: "disconnect",
    //   title: "Inquiry",
    //   fontIcon: "bi-app-indicator",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    // },
  ];
 
  return (
    <>
      {sideBarMenus.map((menu: ISideBarMenu) => {
        return menu.isSuperAdmin || menu.isAdmin ? (
          <div key={menu.title}>
            {menu.child.length ? (
              <SidebarMenuItemWithSub
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              >
                {menu.child.map((menuChild: ISideBarChildMenu) => (
                  <SidebarMenuItem
                    key={menu.title}
                    to={menuChild.path}
                    title={menuChild.title}
                    hasBullet={menuChild.hasBullet}
                  />
                ))}
              </SidebarMenuItemWithSub>
            ) : (
              <SidebarMenuItem
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              />
            )}
          </div>
        ) : null;
      })}
    </>
  );
};
 
export { SidebarMenuMain };