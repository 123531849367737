import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import {
  addRestaurant,
  getCities,
  getCountries,
  getStates,
  uploadPicture,
  getCategories,
  addHotel,
} from "../../api";
import { IRestaurant } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { charValidate } from "../../components/utility";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
import httpsClient from "../../api/httpsClient";
import { APP_URLs } from "../../constants/appURLs";
import Select from 'react-select'

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: IRestaurant;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditHotel = ({ show, handleClose, editData }: Props) => {
  let imgUrl: string = editData?.images
    ? editData?.images[0]
      ? editData?.images[0]
      : ""
    : "";
  const [resaturantImg, setGolfImg] = useState<string>("");
  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean>(true);
  const [isCheckingDomain, setIsCheckingDomain] = useState<boolean>(false);
  const [isDomainChecked, setIsDomainChecked] = useState<boolean>(false);
  const [originalDomain, setOriginalDomain] = useState<string>(editData?.domain || "");
  const [selectedCountry, setSelectedCountry] = useState<any>()
  const [selectedState, setSelectedState] = useState<any>()
  const [selectedCity, setSelectedCity] = useState<any>()

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().required("Description is required"),
    minThreshold: Yup.string().required("ThreshHold amount is required"),
    minSqueezAmount: Yup.string(),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    addressName: Yup.string().trim().required("Address is required"),
    street: Yup.string().trim().required("Street is required"),
    unit: Yup.string().trim().required("Unit is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    country: Yup.string().trim().required("Country is required"),
    zip: Yup.string().trim().required("Zip is required"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number format is incorrect"),
    // reservationLink: Yup.string().trim(),
  });

  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery("countries", getCountries);

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation("states", getStates);
  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation("cities", getCities);
  const {
    mutateAsync: addRestaurantCategory,
    isLoading: isAddingGolf,
    error: addGoldAPIError,
  } = useMutation("add-restaurant", addHotel);

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const restaurant = categoryData?.data?.docs?.find(
    (s: any) => s?._id == process.env.REACT_APP_ID_HOTEL_CATE
  );

  const formik: any = useFormik({
    initialValues: {
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      description: editData?.description ?? "",
      latitude: editData?.LocationLatLng?.coordinates[1] ?? "",
      longitude: editData?.LocationLatLng?.coordinates[0] ?? "",
      minThreshold: editData?.minThreshold ?? 0,
      minSqueezAmount: editData?.minSqueezAmount ?? 0,
      addressName: editData?.address?.name
        ? editData?.address?.name?.replace(/^\s+|\s+$/g, "")
        : "",
      street: editData?.address?.street
        ? editData?.address?.street?.replace(/^\s+|\s+$/g, "")
        : "",
      unit: editData?.address?.unit ?? "",
      city: editData?.address?.cityId ?? "",
      zip: editData?.address?.zip ?? "",
      state: editData?.address?.stateId ?? "",
      country: editData?.address?.countryId ?? "",
      websiteURL: editData?.website
        ? editData?.website?.replace(/^\s+|\s+$/g, "")
        : "",
      mobileNumber: editData?.mobileNumber ?? "",
      restaurantType: editData?.type,
      emailAddress: editData?.emailAddress,
      countryCode: editData?.countryCode ?? "",
      tax: editData?.tax ?? 0,
      convenienceFee: editData?.convenienceFee ?? 0,
      cateId: restaurant?._id,
      menuUrl: editData?.menuUrl,
      instagramUrl: editData?.instagramUrl,
      facebookUrl: editData?.facebookUrl,
      twitterUrl: editData?.twitterUrl,
      awardWinning: editData?.awardWinning,
      price: editData?.price,
      cuisine: editData?.cuisine,
      hoursOfOperation: editData?.hoursOfOperation,
      paymentOptions: editData?.paymentOptions,
      parking: editData?.parking,
      restaurantUrl: editData?.restaurantUrl,
      safety: editData?.safety,
      yelpReview: editData?.yelpReview,
      tripAdvisorReview: editData?.tripAdvisorReview,
      eaterReview: editData?.eaterReview,
      googleReview: editData?.googleReview,
      reservationLink: editData?.reservationLink,
      IsAutoApprove: editData?.IsAutoApprove ?? true,
      domain: editData?.domain,
      primaryColor: editData?.primaryColor,
      secondaryColor: editData?.secondaryColor,
      lightColor: editData?.lightColor,
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      if (values?.domain?.trim() === "" || values?.domain?.trim() === undefined) {
        formik.setFieldError("domain", "Please enter domain name");
        return;
        }
      if (values.domain.trim() !== "" && values.domain !== originalDomain) {
        setIsCheckingDomain(true);
        const isAvailable = await checkDomainAvailability(values.domain, restaurant?._id);
        setIsCheckingDomain(false);
        setIsDomainAvailable(isAvailable);
        setIsDomainChecked(true);
      } else if (!isDomainAvailable && values.domain.trim() !== "") {
        formik.setFieldError("domain", "Domain already exists");
        return; 
      }
    
      if (isDomainAvailable && values.domain.trim() !== "") {
        formik.setFieldError("domain", "");
      }
    
      const country = countryData?.data?.find(
        (c: any) => c?.countryId == values?.country
      );
      const state = stateData?.data?.find(
        (s: any) => s?.stateId == values?.state
      );

      const city = cityData?.data?.find((c: any) => c?.cityId == values?.city);
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        cateId: restaurant?._id,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        description: values?.description ?? "",
        minThreshold: values?.minThreshold ?? 0,
        minSqueezAmount: values?.minSqueezAmount ?? 0,
        address: {
          name: values?.addressName
            ? values?.addressName?.replace(/^\s+|\s+$/g, "")
            : "",
          street: values?.street
            ? values?.street?.replace(/^\s+|\s+$/g, "")
            : "",
          unit: values?.unit ? values?.unit?.replace(/^\s+|\s+$/g, "") : "",
          cityId: values?.city ?? "",
          zip: values?.zip ?? "",
          stateId: values?.state ?? "",
          countryId: values?.country ?? "",
          country: country?.name ?? "",
          state: state?.name ?? "",
          city: city?.name ?? "",
        },
        images: resaturantImg ? [resaturantImg] : "",
        latitude: values?.latitude ?? "",
        longitude: values?.longitude ?? "",
        type: values?.restaurantType,
        emailAddress: values?.emailAddress,
        website: values?.websiteURL
          ? values?.websiteURL?.replace(/^\s+|\s+$/g, "")
          : "",
        menuUrl: values?.menuUrl
          ? values?.menuUrl?.replace(/^\s+|\s+$/g, "")
          : "",
        // mobileNumber: values?.mobileNumber
        // ? values?.mobileNumber
        // : "",
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode.length}`)
          : "",
        countryCode: values?.countryCode ?? "1",
        tax: values?.tax ?? 0,
        convenienceFee: values?.convenienceFee ?? 0,
        instagramUrl: values?.instagramUrl,
        facebookUrl: values?.facebookUrl,
        twitterUrl: values?.twitterUrl,
        awardWinning: values?.awardWinning,
        price: values?.price,
        cuisine: values?.cuisine,
        hoursOfOperation: values?.hoursOfOperation,
        paymentOptions: values?.paymentOptions,
        parking: values?.parking,
        restaurantUrl: values?.restaurantUrl,
        safety: values?.safety,
        yelpReview: values?.yelpReview,
        tripAdvisorReview: values?.tripAdvisorReview,
        eaterReview: values?.eaterReview,
        googleReview: values?.googleReview,
        reservationLink: values?.reservationLink,
        IsAutoApprove: values?.IsAutoApprove ?? true,
        domain: values?.domain,
        primaryColor: values?.primaryColor,
        secondaryColor: values?.secondaryColor,
        lightColor: values?.lightColor,
      };

      let response = await addRestaurantCategory(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Hotel Added Successfully!"
        );
        formik.resetForm();
        setImgFileName("");
        setGolfImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const countryValue = formik.values.country;
  const stateValue = formik.values.state;

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber
      );
      if (imgUrl) {
        setGolfImg(imgUrl);
      }
    }
  }, [editData]);

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue });
    }
  }, [stateValue]);


  
  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setGolfImg(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  
  const checkDomainAvailability = async (domain:any, cateId:any) => {
    try {
      const response = await httpsClient.post(APP_URLs.check_domain.domain_avaliable, {
        domain,
        cateId
      });
      return response.data.available;
    } catch (error) {
      console.error("Error checking domain availability", error);
      return false;
    }
  };

  useEffect(() => {
    if(editData && countryData && stateData && cityData && !selectedState && !selectedCity && !selectedCountry){
      const filteredStateValue = stateData?.data?.filter((Id: any) => Id?.stateId === editData?.address?.stateId)
      const stateValue = {
        label: filteredStateValue[0]?.name,
        value: filteredStateValue[0]?.stateId
      }
      setSelectedState(stateValue)

      const filteredCityValue = cityData?.data?.filter((Id: any) => Id?.cityId === editData?.address?.cityId)
      const cityValue = {
        label: filteredCityValue[0]?.name,
        value: filteredCityValue[0]?.cityId
      }
      setSelectedCity(cityValue)

      const filteredCountryValue = countryData?.data?.filter((Id: any) => Id?.countryId === editData?.address?.countryId)
      const countryValue = {
        label: filteredCountryValue[0]?.name,
        value: filteredCountryValue[0]?.countryId
      }
      setSelectedCountry(countryValue)
    }
  },[countryData, stateData, cityData, editData])

  const countryOptions = countryData?.data?.map((country: any) => ({
    value: country?.countryId,
    label: `${country?.name} (${country?.code})`,
  }));

  const stateOptions = stateData?.data?.map((state: any) => ({
    value: state?.stateId,
    label: `${state?.name}`,
  }));

  const cityOptions = cityData?.data?.map((city: any) => ({
    value: city?.cityId,
    label: `${city?.name}`,
  }));

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => {
        setImgFileName("");
        setGolfImg("");
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} Hotel</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            setImgFileName("");
            setGolfImg("");
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ height: "80vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number{''} <span className="required-icon">*</span></label>
                <PhoneInput
                  country={"us"}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || ""
                    );
                    formik.setFieldValue("mobileNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.mobileNumber}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">Description{''} <span className="required-icon">*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditHotel.modules}
                  formats={AddEditHotel.formats}
                  value={formik.values.description}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}
            <Row
              className={`${formik.touched.rulesDescription &&
                formik.errors.rulesDescription
                ? "pt-6"
                : "pt-17"
                }`}
            >
              <Col xs={6}>
                <label className="form-label fw-bold">Latitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Latitude"
                  aria-label="Latitude"
                  {...formik.getFieldProps("latitude")}
                />
                {formik.touched.latitude && formik.errors.latitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.latitude}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6}>
                <label className="form-label fw-bold">Longitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Longitude"
                  aria-label="Longitude"
                  {...formik.getFieldProps("longitude")}
                />
                {formik.touched.longitude && formik.errors.longitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.longitude}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <label className="col-lg-4 col-form-label fw-bold fs-6 mt-3">
              <span>Address</span>
            </label>
            <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Address Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("addressName")}
                />
                {formik.touched.addressName && formik.errors.addressName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressName}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Street{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Street"
                  aria-label="Street"
                  {...formik.getFieldProps("street")}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.street}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Unit{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Unit"
                  aria-label="Unit"
                  {...formik.getFieldProps("unit")}
                />
                {formik.touched.unit && formik.errors.unit && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.unit}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country{''} <span className="required-icon">*</span></label>
                {/* <select
                  disabled={isLoadingCountry}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("country")}
                >
                  <option value="">Select Country</option>
                  {countryData?.data
                    ? countryData?.data?.map((country: any) => (
                      <option
                        value={country?.countryId}
                        key={country?._id}
                      >{`${country?.name} (${country?.code})`}</option>
                    ))
                    : null}
                </select> */}

<Select
                  isDisabled={isLoadingCountry}
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('country', selectedOption?.value);
                    setSelectedCountry(selectedOption);
                    setSelectedState(null);
                    setSelectedCity(null)
                    formik.setFieldValue('state', );
                    formik.setFieldValue('city', );
                  }}
                  placeholder="Select Country"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.country}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State{''} <span className="required-icon">*</span></label>
                {/* <select
                  disabled={isLoadingState}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("state")}
                >
                  <option value="">Select State</option>
                  {stateData?.data
                    ? stateData?.data?.map((state: any) => (
                      <option value={state?.stateId} key={state?._id}>
                        {state?.name}
                      </option>
                    ))
                    : null}
                </select> */}

<Select
                  isDisabled={isLoadingState}
                  options={stateOptions}  // Pass the options for react-select (state data)
                  value={selectedState}  // Set the selected value
                  onChange={(selectedOption: any) => 
                    {
                     formik.setFieldValue('state', selectedOption?.value);
                     setSelectedState(selectedOption);
                     setSelectedCity(null);
                     formik.setFieldValue('city', )
                    }} // Update Formik on selection
                  placeholder="Select State"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.state}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City{''} <span className="required-icon">*</span></label>
                {/* <select
                  disabled={isLoadingCity}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("city")}
                >
                  <option value="">Select City</option>
                  {cityData?.data
                    ? cityData?.data?.map((city: any) => (
                      <option value={city?.cityId} key={city?._id}>
                        {city?.name}
                      </option>
                    ))
                    : null}
                </select> */}

<Select
                  isDisabled={isLoadingCity}
                  options={cityOptions}
                  value={selectedCity}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('city', selectedOption?.value);
                    setSelectedCity(selectedOption);
                  }}
                  placeholder="Select City"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.city}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.zip}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Website URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Website URL"
                  aria-label="Website URL"
                  {...formik.getFieldProps("websiteURL")}
                />
                {formik.touched.websiteURL && formik.errors.websiteURL && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.websiteURL}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Email</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Email"
                  aria-label="Email"
                  {...formik.getFieldProps("emailAddress")}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.emailAddress}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Type</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Type"
                  aria-label="Type"
                  {...formik.getFieldProps("restaurantType")}
                />
                {formik.touched.restaurantType && formik.errors.restaurantType && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.restaurantType}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Tax (%)</label>
                <AmountInput
                  placeholder="Tax"
                  formikProps={{ ...formik.getFieldProps("tax") }}
                  handleChange={(e) => formik.handleChange(e)}
                  isPercentage={true}
                />
                {/* {formik.touched.tax && formik.errors.tax && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.tax}</div>
                  </div>
                )} */}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">
                  Convenience Fee (Amount)
                </label>
                <AmountInput
                  placeholder="Convenience Fee"
                  formikProps={{ ...formik.getFieldProps("convenienceFee") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {/* {formik.touched.convenienceFee && formik.errors.convenienceFee && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.convenienceFee}
                    </div>
                  </div>
                )} */}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Hotel Menu</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Hotel Menu URL"
                  aria-label="Hotel Menu URL"
                  {...formik.getFieldProps("menuUrl")}
                />
                {formik.touched.menuUrl && formik.errors.menuUrl && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.menuUrl}</div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Image upload</label>
                <div>
                  <label
                    htmlFor="golf-img"
                    className="form-control form-control-lg form-control-solid mb-0 mb-lg-0 cursor-pointer"
                  >
                    {imgFileName
                      ? charValidate(imgFileName, 25)
                      : "Choose File"}
                  </label>
                  <input
                    id="golf-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-0 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      display: "none",
                    }}
                  />
                </div>
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Facebook Url</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Facebook Url"
                  aria-label="Facebook Url"
                  {...formik.getFieldProps("facebookUrl")}
                />
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Instagram URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Instagram Url"
                  aria-label="Instagram Url"
                  {...formik.getFieldProps("instagramUrl")}
                />
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Twitter URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Twitter Url"
                  aria-label="Twitter Url"
                  {...formik.getFieldProps("twitterUrl")}
                />
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Award Winning</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Award Winning"
                  aria-label="Type"
                  {...formik.getFieldProps("awardWinning")}
                />
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Price</label>
                <AmountInput
                  placeholder="Price"
                  formikProps={{ ...formik.getFieldProps("price") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Cuisine</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Cuisine"
                  aria-label="Type"
                  {...formik.getFieldProps("cuisine")}
                />
              </Col>

              {/* <Col  xs={6} className="pt-3">
                <label className="form-label fw-bold">Hours Of Operation</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Hours Of Operation"
                  aria-label="Type"
                  {...formik.getFieldProps("hoursOfOperation")}
                />
              </Col> */}

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Payment Options</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Payment Options"
                  aria-label="Type"
                  {...formik.getFieldProps("paymentOptions")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Hotel Website</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Website"
                  aria-label="Type"
                  {...formik.getFieldProps("restaurantUrl")}
                />
              </Col>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Yelp Review</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Yelp Review"
                  aria-label="yelpReview"
                  {...formik.getFieldProps("yelpReview")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">
                  Trip Advisor Review
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Trip Advisor Review"
                  aria-label="tripAdvisorReview"
                  {...formik.getFieldProps("tripAdvisorReview")}
                />
              </Col>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Eater Review</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Eater Review"
                  aria-label="eaterReview"
                  {...formik.getFieldProps("eaterReview")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Google Review</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Google Review"
                  aria-label="googleReview"
                  {...formik.getFieldProps("googleReview")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Reservation Link</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Reservation Link"
                  aria-label="reservationLink"
                  {...formik.getFieldProps("reservationLink")}
                />
              </Col>

              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Minimum Threshold Amount</label>
                <AmountInput
                  placeholder="Minimum Threshold Amount"
                  formikProps={{ ...formik.getFieldProps("minThreshold") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.minThreshold && formik.errors.minThreshold && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.minThreshold}
                    </div>
                  </div>
                )}
              </Col>
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Maximum Threshold Amount
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Maximum Threshold Amount"
                    {...formik.getFieldProps("minSqueezAmount")}
                  />
                  {formik.touched.minSqueezAmount &&
                    formik.errors.minSqueezAmount && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.minSqueezAmount}
                        </div>
                      </div>
                    )}
                </Col>

                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Auto Approve</label>

                  <Form.Check
                    type="switch"
                    checked={formik.values.IsAutoApprove}
                    id="custom-switch"
                    {...formik.getFieldProps("IsAutoApprove")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>

              </Row>

              <Row className="pt-6">
                <Col xs={12}>
                  <label className="form-label fw-bold">
                    Hours Of Operation
                  </label>
                  <ReactQuill
                    theme="snow"
                    placeholder="Write something..."
                    modules={AddEditHotel.modules}
                    formats={AddEditHotel.formats}
                    value={formik.values.hoursOfOperation}
                    onChange={(content) =>
                      formik.setFieldValue("hoursOfOperation", content)
                    }
                    style={{
                      height: "12rem",
                    }}
                  />
                </Col>
              </Row>

              <Row className="pt-16">
                <Col xs={12}>
                  <label className="form-label fw-bold">Parking</label>
                  <ReactQuill
                    theme="snow"
                    placeholder="Write something..."
                    modules={AddEditHotel.modules}
                    formats={AddEditHotel.formats}
                    value={formik.values.parking}
                    onChange={(content) =>
                      formik.setFieldValue("parking", content)
                    }
                    style={{
                      height: "12rem",
                    }}
                  />
                </Col>

              </Row>

              {/* <Col  xs={6} className="pt-6">
                <label className="form-label fw-bold">Parking</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Parking"
                  aria-label="Type"
                  {...formik.getFieldProps("parking")}
                />
              </Col> */}

              <Row className="pt-16">
                <Col xs={12}>
                  <label className="form-label fw-bold">Safety</label>
                  <ReactQuill
                    theme="snow"
                    placeholder="Write something..."
                    modules={AddEditHotel.modules}
                    formats={AddEditHotel.formats}
                    value={formik.values.safety}
                    onChange={(content) =>
                      formik.setFieldValue("safety", content)
                    }
                    style={{
                      height: "12rem",
                    }}
                  />
                </Col>
              </Row>

              <Row
                className={`${formik.touched.rulesDescription &&
                  formik.errors.rulesDescription
                  ? "pt-6"
                  : "pt-17"
                  }`}
              >
                <Col xs={6}>
                  <label className="form-label fw-bold">Domain URL{''} <span className="required-icon">*</span></label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Domain URL"
                    {...formik.getFieldProps("domain")}
                   
                  />
                  {formik.touched.domain && formik.errors.domain && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.domain}</div>
                    </div>
                  )}
                  {isCheckingDomain && <p>Checking domain availability...</p>}
                </Col>

                <Col xs={6}>
                  <label className="form-label fw-bold">Primary Color</label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Primary Color"
                    {...formik.getFieldProps("primaryColor")}
                  />
                  {formik.touched.primaryColor &&
                    formik.errors.primaryColor && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.primaryColor}
                        </div>
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="pt-3">
                <Col xs={6}>
                  <label className="form-label fw-bold">Secondary Color</label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Secondary Color"
                    {...formik.getFieldProps("secondaryColor")}
                  />
                  {formik.touched.secondaryColor &&
                    formik.errors.secondaryColor && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.secondaryColor}
                        </div>
                      </div>
                    )}
                </Col>

                <Col xs={6}>
                  <label className="form-label fw-bold">Light Color</label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Light Color"
                    {...formik.getFieldProps("lightColor")}
                  />
                  {formik.touched.lightColor &&
                    formik.errors.lightColor && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lightColor}
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
            </Row>
 

          </Container>
          <div className="w-100 d-flex align-items-center justify-content-end mt-15">
            <Button
              onClick={() => {
                formik.resetForm();
                setImgFileName("");
                setGolfImg("");
                handleClose(false);
              }}
              style={{ marginRight: "5px" }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" size="lg" variant="primary">
              {isAddingGolf ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

AddEditHotel.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditHotel.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default AddEditHotel;
