import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CancelIcon from '@mui/icons-material/Cancel';
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import {
  addEditWellness,
  getCategories,
  getCities,
  getCountries,
  getStates,
  uploadPicture,
} from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { charValidate } from "../../components/utility";
import Select from 'react-select';
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditWellness = ({ show, handleClose, editData }: Props) => {
  let imgUrl: string = editData?.images
    ? editData?.images[0]
      ? editData?.images[0]
      : ""
    : "";
  const [wellnessImg, setGolfImg] = useState<string>("");
  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );
  const [selectedCountry, setSelectedCountry] = useState<any>()
  const [selectedState, setSelectedState] = useState<any>()
  const [selectedCity, setSelectedCity] = useState<any>()

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().required("Description is required"),
    minThreshold: Yup.string().required("ThreshHold amount is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    addressName: Yup.string().trim().required("Address is required"),
    street: Yup.string().trim().required("Street is required"),
    unit: Yup.string().trim().required("Unit is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    country: Yup.string().trim().required("Country is required"),
    zip: Yup.string().trim().required("Zip is required"),
    websiteURL: Yup.string().trim().required("Website URL is required"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number format is incorrect"),
    minSqueezAmount: Yup.string(),

  });

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery("countries", getCountries);

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation("states", getStates);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation("cities", getCities);

  const {
    mutateAsync: addWellness,
    isLoading: isLoadingAddWellness,
    error: APIErrorOnAdding,
  } = useMutation("add-golf", addEditWellness);

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);

  
  const restaurant = categoryData?.data?.docs?.find(
    (s: any) => s?._id == process.env.REACT_APP_ID_SALOON_CATE
  );

  const formik: any = useFormik({
    initialValues: {
      cateId: restaurant?._id,
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      type: editData?.type ?? "",
      description: editData?.description ?? "",
      latitude: editData?.LocationLatLng?.coordinates[0] ?? "",
      longitude: editData?.LocationLatLng?.coordinates[1] ?? "",
      minThreshold: editData?.minThreshold ?? 0,
      addressName: editData?.address?.name
        ? editData?.address?.name?.replace(/^\s+|\s+$/g, "")
        : "",
      street: editData?.address?.street
        ? editData?.address?.street?.replace(/^\s+|\s+$/g, "")
        : "",
      unit: editData?.address?.unit ?? "",
      city: editData?.address?.cityId ?? "",
      zip: editData?.address?.zip ?? "",
      state: editData?.address?.stateId ?? "",
      country: editData?.address?.countryId ?? "",
      websiteURL: editData?.website
        ? editData?.website?.replace(/^\s+|\s+$/g, "")
        : "",
      mobileNumber: editData?.mobileNumber ?? "",
      countryCode: editData?.countryCode ?? "",
      minSqueezAmount: editData?.minSqueezAmount ?? 0,
      IsAutoApprove: editData?.IsAutoApprove ?? true,
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {

      const country = countryData?.data?.find(
        (c: any) => c?.countryId == values?.country
      );
      const state = stateData?.data?.find(
        (s: any) => s?.stateId == values?.state
      );
      const city = cityData?.data?.find((c: any) => c?.cityId == values?.city);
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        cateId: restaurant?._id,
        name: values?.name ?? "",
        images: wellnessImg ? [wellnessImg] : "",
        website: values?.websiteURL ?? "",
        countryCode: values?.countryCode ?? "1",
        minThreshold: values?.minThreshold ?? 0,
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        description: values?.description ?? "",
        address: {
          name: values?.addressName
            ? values?.addressName?.replace(/^\s+|\s+$/g, "")
            : "",
          street: values?.street
            ? values?.street?.replace(/^\s+|\s+$/g, "")
            : "",
          unit: values?.unit ? values?.unit?.replace(/^\s+|\s+$/g, "") : "",
          cityId: values?.city ?? "",
          zip: values?.zip ?? "",
          stateId: values?.state ?? "",
          countryId: values?.country ?? "",
          country: country?.name ?? "",
          state: state?.name ?? "",
          city: city?.name ?? "",
        },
        latitude: values?.latitude ?? 72.57,
        longitude: values?.longitude ?? 72.57,
        minSqueezAmount: values?.minSqueezAmount ?? 0,
        IsAutoApprove: values?.IsAutoApprove ?? true,
      };

      let response = await addWellness(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Wellness Added Successfully!"
        );
        formik.resetForm();
        setImgFileName("");
        setGolfImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const countryValue = formik.values.country;
  const stateValue = formik.values.state;

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber
      );
      if (imgUrl) {
        setGolfImg(imgUrl);
      }
    }
  }, [editData]);

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue });
    }
  }, [stateValue]);


  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setGolfImg(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  const handleRemoveImage = () => {
    setImgFileName("")
    setGolfImg("")
  }

  useEffect(() => {
    if(editData && countryData && stateData && cityData && !selectedState && !selectedCity && !selectedCountry){
      const filteredStateValue = stateData?.data?.filter((Id: any) => Id?.stateId === editData?.address?.stateId)
      const stateValue = {
        label: filteredStateValue[0]?.name,
        value: filteredStateValue[0]?.stateId
      }
      setSelectedState(stateValue)

      const filteredCityValue = cityData?.data?.filter((Id: any) => Id?.cityId === editData?.address?.cityId)
      const cityValue = {
        label: filteredCityValue[0]?.name,
        value: filteredCityValue[0]?.cityId
      }
      setSelectedCity(cityValue)

      const filteredCountryValue = countryData?.data?.filter((Id: any) => Id?.countryId === editData?.address?.countryId)
      const countryValue = {
        label: filteredCountryValue[0]?.name,
        value: filteredCountryValue[0]?.countryId
      }
      setSelectedCountry(countryValue)
    }
  },[countryData, stateData, cityData, editData])

  const countryOptions = countryData?.data?.map((country: any) => ({
    value: country?.countryId,
    label: `${country?.name} (${country?.code})`,
  }));

  const stateOptions = stateData?.data?.map((state: any) => ({
    value: state?.stateId,
    label: `${state?.name}`,
  }));

  const cityOptions = cityData?.data?.map((city: any) => ({
    value: city?.cityId,
    label: `${city?.name}`,
  }));

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => {
        setImgFileName("");
        setGolfImg("");
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} Wellness</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            setImgFileName("");
            setGolfImg("");
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ height: "80vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Image upload</label>
                <div>
                {imgFileName != "" ? (
                   <div className="add-golf-image-upload">
                   <p style={{ display: "inline-block", marginRight: "30px", marginTop:"12px", paddingLeft:"13px" }}>{charValidate(imgFileName, 35)}</p>
                   <CancelIcon
                     onClick={handleRemoveImage}
                     className="file-remove-icon"
                   />
                 </div>
                  ):(
                    <>
                    <label
                    htmlFor="golf-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                    >
                    {imgFileName
                    ? charValidate(imgFileName, 25)
                    : "Choose File"}
                    </label>
                  <input
                    id="golf-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      visibility: "hidden",
                    }}
                  />
                  </>
                  )}
                </div>
                {/* {formik.touched.golfImage && formik.errors.golfImage && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.golfImage}
                    </div>
                  </div>
                )} */}
              </Col>
              <Row>
                <Col xs={6}>
                  <label className="form-label fw-bold">Mobile Number{''} <span className="required-icon">*</span></label>
                  <PhoneInput
                    country={"us"}
                    // variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.mobileNumber}
                    onChange={(value: string, country: any) => {
                      formik.setFieldValue(
                        "countryCode",
                        country?.dialCode || ""
                      );
                      formik.setFieldValue("mobileNumber", value || "");
                    }}
                    inputStyle={{
                      background: "#f9f9f9",
                      border: "none",
                    }}
                    inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    dropdownStyle={{
                      maxHeight: "200px",
                    }}
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.mobileNumber}
                      </div>
                    </div>
                  )}
                </Col>

                <Col xs={6}>
                  <label className="form-label fw-bold">Website URL{''} <span className="required-icon">*</span></label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Website URL"
                    aria-label="Website URL"
                    {...formik.getFieldProps("websiteURL")}
                  />
                  {formik.touched.websiteURL && formik.errors.websiteURL && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.websiteURL}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Latitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Latitude"
                  aria-label="Latitude"
                  {...formik.getFieldProps("latitude")}
                />
                {formik.touched.latitude && formik.errors.latitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.latitude}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Longitude{''} <span className="required-icon">*</span></label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Longitude"
                  aria-label="Longitude"
                  {...formik.getFieldProps("longitude")}
                />
                {formik.touched.longitude && formik.errors.longitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.longitude}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Address Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("addressName")}
                />
                {formik.touched.addressName && formik.errors.addressName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressName}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Street{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Street"
                  aria-label="Street"
                  {...formik.getFieldProps("street")}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.street}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Unit{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Unit"
                  aria-label="Unit"
                  {...formik.getFieldProps("unit")}
                />
                {formik.touched.unit && formik.errors.unit && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.unit}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country{''} <span className="required-icon">*</span></label>
                {/* <select
                  disabled={isLoadingCountry}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("country")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    let selectedCountry = e.target.value;
                    if (!selectedCountry) {
                      formik.setFieldValue("state", "");
                      formik.setFieldValue("city", "");
                      stateData.data = "";
                      cityData.data = "";
                    }
                  }}
                >
                  <option value="">Select Country</option>
                  {countryData?.data
                    ? countryData?.data?.map((country: any) => (
                      <option
                        value={country?.countryId}
                        key={country?._id}
                      >{`${country?.name} (${country?.code})`}</option>
                    ))
                    : null}
                </select> */}

<Select
                  isDisabled={isLoadingCountry}
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('country', selectedOption?.value);
                    setSelectedCountry(selectedOption);
                    setSelectedState(null);
                    setSelectedCity(null)
                    formik.setFieldValue('state', );
                    formik.setFieldValue('city', );
                  }}
                  placeholder="Select Country"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.country}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State{''} <span className="required-icon">*</span></label>
                {/* <select
                  disabled={isLoadingState}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("state")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const selectedState = e.target.value;
                    if (!selectedState) {
                      formik.setFieldValue("city", "");
                      cityData.data = "";
                    }
                  }}
                >
                  <option value="">Select State</option>
                  {stateData?.data
                    ? stateData?.data?.map((state: any) => (
                      <option value={state?.stateId} key={state?._id}>
                        {state?.name}
                      </option>
                    ))
                    : null}
                </select> */}

<Select
                  isDisabled={isLoadingState}
                  options={stateOptions}  // Pass the options for react-select (state data)
                  value={selectedState}  // Set the selected value
                  onChange={(selectedOption: any) => 
                    {
                     formik.setFieldValue('state', selectedOption?.value);
                     setSelectedState(selectedOption);
                     setSelectedCity(null);
                     formik.setFieldValue('city', )
                    }}
                  placeholder="Select State"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.state}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City{''} <span className="required-icon">*</span></label>
                {/* <select
                  disabled={isLoadingCity}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("city")}
                >
                  <option value="">Select City</option>
                  {cityData?.data
                    ? cityData?.data?.map((city: any) => (
                      <option value={city?.cityId} key={city?._id}>
                        {city?.name}
                      </option>
                    ))
                    : null}
                </select> */}

<Select
                  isDisabled={isLoadingCity}
                  options={cityOptions}
                  value={selectedCity}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('city', selectedOption?.value);
                    setSelectedCity(selectedOption);
                  }}
                  placeholder="Select City"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      boxShadow: 'none',
                      borderColor: 'transparent',
                    }),
                    option: (provided: any) => ({
                      ...provided,
                      padding: '10px',
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: 'auto',
                      zIndex: 9999,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      padding: '0',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.city}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zip}</div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">
                Minimum Threshold Amount
                </label>

                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Minimum Threshold Amount"
                  {...formik.getFieldProps("minThreshold")}
                />
                {formik.touched.minThreshold && formik.errors.minThreshold && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.minThreshold}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
            <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">
                  Maximum Threshold Amount
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Maximum Threshold Amount"
                  {...formik.getFieldProps("minSqueezAmount")}
                />
                {formik.touched.minSqueezAmount &&
                  formik.errors.minSqueezAmount && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.minSqueezAmount}
                      </div>
                    </div>
                  )}

              </Col>
              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Auto Approve</label>
                  
                  <Form.Check
                    type="switch"
                    checked={formik.values.IsAutoApprove}
                    id="custom-switch"
                    {...formik.getFieldProps("IsAutoApprove")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={12} style={{ marginBottom: "48px" }}>
                <label className="form-label fw-bold">Description{''} <span className="required-icon">*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditWellness.modules}
                  formats={AddEditWellness.formats}
                  value={formik.values.description}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container mt-14">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={12}>
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setImgFileName("");
                      setGolfImg("");
                      handleClose(false);
                    }}
                    size="lg"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <div className="w-15px"></div>
                  <Button type="submit" size="lg" variant="primary">
                    {isLoadingAddWellness ? "Loading..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

AddEditWellness.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditWellness.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default AddEditWellness;
