import { Box, CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import { PageTitle } from "../../_metronic/layout/core";
import {   getGolfInfo } from "../../api";
import AddEditProvider from "../../components/third-provider/AddEditProvider";
import { charValidate } from "../../components/utility";
import AddEditInvoke from "../../components/third-provider/AddEditInvoke";

const GolfDetails: React.FC = () => {

  const [showAddEditAPIPopup, setShowAddEditAPIPopup] =
    useState<boolean>(false);
    const [showAddEditAPIPopupInvoke, setShowAddEditAPIPopupInvoke] =
    useState<boolean>(false);
    const [hasFortisState, setHasFortisState] = useState<boolean>(false);
  const tableRef = useRef();
  const location: any = useLocation();
  const golfDetailInfo = location?.state;
  const isForTokenGeneration = location?.state?.isForTokenGeneration
  const paymentFlag = location?.state?.payment; // Access the payment flag here
  const navigate = useNavigate();

//   const {
//     mutateAsync: getRestaurantData,
//     data: restaurantData,
//     isLoading: isGettingRestaurantData,
//     error,
//   } = useMutation("get-golf", getRestaurantInfo);
 

    // Loading state for fetching slots
    // const loading = isGettingRestaurantData || isLoading || isdeletingGolf || isdeletingWidget;
    const loading = false;
    const getGolf = async () => {
      await getRestaurantData(golfDetailInfo?.restaurantData?._id ?? "");
    };
  useEffect(() => {
    getGolf();
  }, []);
const {
  mutateAsync: getRestaurantData,
  data: restaurantData,
  isLoading: isGettingRestaurantData,
  error,
} = useMutation("get-golf", getGolfInfo);

useEffect(() => {
  if (restaurantData?.data) {
    const paymentInfo = restaurantData.data.paymentInfo;
    // Check if fortis exists or if paymentInfo is an empty array
    const fortisExists = paymentInfo?.length === 0 || paymentInfo?.some((payment: any) => payment?.fortis) ||  paymentInfo?.every((payment: any) => Object.keys(payment).length === 0);
      setHasFortisState(fortisExists);
  }
}, [restaurantData?.data]);
 
const renderProviderFields = (fields: any, provider?: string, isBookingInfo: boolean = false) => {
  if (!fields) return null;
  // Define a mapping for specific keys and their corresponding display labels
  const keyLabelMapping: { [key: string]: string } = {
    developerId: "Merchant Developer ID",
    publicPrivateKey: "Merchant API Key",
    secretKey: "Merchant User ID",
    accountId: provider?.toLowerCase() === 'fiserv' ? "Merchant ID" : "Merchant Location ID",
    public_private_key: "Private Key",
    flatFees: "Flat Fees",
    venueId: provider?.toLowerCase() === "book4times" ? "Location ID" : "Venue ID",
  };

  // Filter and map the fields
  const filteredFields = Object.entries(fields).filter(([key]) => {
    // Always filter out these fields
    if (['flatFees', 'perFlatFees'].includes(key)) {
      return false;
    }
    // Filter out accountId only in booking information
    if (isBookingInfo && key === 'accountId') {
      return false;
    }
    return true;
  });
  return filteredFields.map(([key, value]: [string, any]) => {
    // Special handling for 'contractorFields'
    if (key === 'contractorFields' && Array.isArray(value)) {
      return (
        <div key={key} className="mt-2">
          <div className="text-muted mb-2" style={{ fontWeight: "bold" }}>Involved Party:</div>
          {value.map((contractor: any, index: number) => (
            <div key={index} className="ml-4 mb-2">
              {contractor.name && <div><span style={{ fontWeight: "bold" }}>Brand Name: </span>{contractor.name ?? "N/A"}</div>}
              {contractor.contractorName &&<div><span style={{ fontWeight: "bold" }}>Selected Reservation Provider: </span>{contractor.contractorName ?? "N/A"}</div>}
              {contractor.contractorFieldId && <div><span style={{ fontWeight: "bold" }}>Contractor ID: </span>{contractor.contractorFieldId ?? "N/A"}</div>}
              <div><span style={{ fontWeight: "bold" }}>Split Percentage: </span>{contractor.contractorPer}{contractor.contractorPer ? "%": ""}</div>
              {index !== value.length - 1 && <hr />} 
            </div>
          ))}
        </div>
      );
    }

    // Use the keyLabelMapping to display custom labels if the key exists in the mapping
    const displayLabel = keyLabelMapping[key] || key.replace(/_/g, " ")?.charAt(0).toUpperCase() + key?.slice(1);
    
    return (
      <div key={key} className="mb-1">
        <span style={{ fontWeight: "bold" }}>
          {displayLabel}
            {/* {key} */}
        </span>
        :
        <span style={{ marginLeft: "5px" }}>{(!value || value === "") ? "N/A" : value}</span>
      </div>
    );
  });
};
const renderProviderInfo = (info: any, isBookingInfo: boolean = false) => {
  if (!info || !Array.isArray(info) || info.length === 0) return null;

  return info.map((item, index) => {
    const provider = Object.keys(item)[0];
    const providerData = item[provider];
    return (
      <div key={index} className="mb-4">
       {provider ? <div className="font-weight-bold mb-2" style={{fontWeight: "bold"}}>
          {provider === "fiserv" || provider === "fortis" || provider === "shift4" || provider === "stripe" 
            ? "Selected Payment gateway" 
            : "Booking Provider"}  : {provider ? provider?.charAt(0).toUpperCase() + provider?.slice(1) : ""}
        </div> : "No Provider Available"}
        <div className="pl-3">
          {/* <div> <span style={{fontWeight: "bold"}}>Provider ID:  </span>{providerData.providerId ?? "N/A"}</div> */}
          {/* {providerData.perFlatFees !== undefined && (
            <div> <span style={{fontWeight: "bold "}}>Per Flat Fees:  </span> {providerData.perFlatFees ?? "N/A"}%</div>
          )}
          {providerData.flatFees !== undefined && (
            <div> <span style={{fontWeight: "bold"}}>Flat Fees: </span>${providerData.flatFees ?? "N/A"}</div>
          )} */}
          {providerData?.fields && (
            <div className="mt-2">
              <div className="text-muted mb-1" style={{fontWeight: "bold"}}>Fields:</div>
              <div className="pl-3">
              {renderProviderFields(providerData?.fields, provider, isBookingInfo)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });
};


const PaymentInfoCard = () => (
  <Card style={{ height: "100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 20px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px" }}>
    <Card.Body>
      <Card.Title className="pb-5">Payment Information</Card.Title>
      <Container style={{ marginLeft: 0, paddingLeft: 0 }}>
        {restaurantData?.data?.paymentInfo && 
          (Array.isArray(restaurantData.data.paymentInfo) && restaurantData.data.paymentInfo.length > 0) ? (
            renderProviderInfo(restaurantData.data.paymentInfo, false) /* Explicitly passing false */
          ) : (
            <>
            <div className="text-muted">No payment information available</div>
            </>
          )}
      </Container>
    </Card.Body>
  </Card>
);
const BookingInfoCard = () => {
  const hasBookingInfo = restaurantData?.data?.bookingInfo && 
    (Array.isArray(restaurantData.data.bookingInfo) 
      ? restaurantData.data.bookingInfo.length > 0 
      : Object.keys(restaurantData.data.bookingInfo).length > 0);

  return (
    <Card style={{ height: "100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 20px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px" }}>
      <Card.Body>
        <Card.Title className="pb-5">Booking Information</Card.Title>
        <Container style={{ marginLeft: 0, paddingLeft: 0 }}>
          {hasBookingInfo ? (
            <>
              {renderProviderInfo(restaurantData.data.bookingInfo, true)} {/* Explicitly passing true */}
              <div>
                <span style={{ fontWeight: "bold" }}>Split percentage for merchant: </span> 
                {restaurantData.data.perForMerchant   ?? "N/A" }{restaurantData.data.perForMerchant ? "%" : ""}
              </div>
            </>
          ) : (
            <div className="text-muted">No booking information available</div>
          )}
        </Container>
      </Card.Body>
    </Card>
  );
};


const InfoCardsContainer = () => (
  <div className="mt-4">
    <Row>
      <Col md={6} className="pe-3">
        <PaymentInfoCard />
      </Col>
      <Col md={6} className="ps-3">
        <BookingInfoCard />
      </Col>
    </Row>
  </div>
);

  const handleAddEditAPIModalClose = () => {
    setShowAddEditAPIPopup(false);
    getGolf();

  };

  const handleAddEditAPIModalCloseInvoke = () => {
    setShowAddEditAPIPopupInvoke(false);
      getGolf(); 
  };

  const handleBackClick = () => {
    navigate("/category-golf-club");
  };

  

  
  const GolfDetailsCard = () => {
    return (
      <Card style={{ width: "100%", boxShadow: "none", marginTop: "40px" }} className="mb-4">
        <Card.Body>
          <Card.Title className="pb-5">Golf Details</Card.Title>
          <Container style={{marginLeft:0}}>
            <Row>
              <Col xs={6} style={{ paddingLeft: 0 }}>
                <Card.Subtitle className="text-muted">Name:</Card.Subtitle>
                <Card.Text>{golfDetailInfo?.restaurantData?.name ?? "N/A"}</Card.Text>
              </Col>
              <Col xs={6}>
                <Card.Subtitle className="text-muted">Address:</Card.Subtitle>
                <Card.Text>{`${golfDetailInfo?.restaurantData?.address?.name}${", "}${golfDetailInfo?.restaurantData?.address?.street}${", "}${golfDetailInfo?.restaurantData?.address?.city}${", "}${golfDetailInfo?.restaurantData?.address?.state}${", "}${golfDetailInfo?.restaurantData?.address?.zip ?? "N/A"}`}</Card.Text>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={6} style={{ paddingLeft: 0 }}>
                <Card.Subtitle className="text-muted">
                Description:
                </Card.Subtitle>
                <Card.Text className="mt-1" style={{ maxHeight: "30vh"}}>
                {/* <Card.Text>{golfDetailInfo?.restaurantData?.mobileNumber ?? "N/A"}</Card.Text> */}
                  <div dangerouslySetInnerHTML={{ __html: golfDetailInfo?.restaurantData?.description ?? "N/A" }} />
                </Card.Text>
              </Col>
            </Row>
          </Container>
        { !paymentFlag  && <Row className="mt-5">
            <Card.Subtitle style={{color:"black !important", fontSize:"14.95px"}}>
              Slot Restriction:
            </Card.Subtitle>
          </Row>}
        </Card.Body>
      </Card>
    );
  };

  return (
    <Card className="p-6">
      <PageTitle>{golfDetailInfo?.restaurantData?.name ?? ""}</PageTitle>
      <>
        <KTCard>
            <>
            { loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '300px' }}>
                <CircularProgress />
              </Box>
            ) : ( <> <div className="pb-5 px-5 d-flex align-items-center" style={{width:"100%"}}>
              <div style={{width:"43%"}}>
                <Button
                  variant="secondary"
                  className="d-flex align-items-center"
                  onClick={handleBackClick}
                >
                  <KTIcon iconName="arrow-left" className="fs-1" />
                  Back
                </Button>
                </div >
                  <div className="d-flex align-items-center justify-content-end" style={{width:"57%"}}>
                <Button
                  className="d-flex align-items-center ms-3"
                  onClick={() => setShowAddEditAPIPopup(true)}
                >
                  <KTIcon iconName="plus" className="fs-1" />
                  Add/Edit Payment & Booking Provider
                </Button>
               {!hasFortisState  && <Button
                  className="d-flex align-items-center ms-3"
                  onClick={() => setShowAddEditAPIPopupInvoke(true)}
                >
                  <KTIcon iconName="plus" className="fs-1" />
                  Add/Edit Involved Party
                </Button>}
                </div>
                </div>
        <GolfDetailsCard /> 
         <InfoCardsContainer />
        </> )}
        </>     
      </KTCard>
      </>
      {showAddEditAPIPopup && (
          <AddEditProvider
          show={showAddEditAPIPopup}
          handleClose={handleAddEditAPIModalClose}
          editData={golfDetailInfo?.restaurantData}       
          />

       )}
        {!hasFortisState  && showAddEditAPIPopupInvoke && (
    <AddEditInvoke
        show={showAddEditAPIPopupInvoke}
        handleClose={handleAddEditAPIModalCloseInvoke}
        editData={restaurantData?.data}       
        // editData={golfDetailInfo?.restaurantData}    
    />
)}
    </Card>
  );
};

export default GolfDetails;
