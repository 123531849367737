import { useFormik } from "formik";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "react-query";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { changePassword } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CryptoJS from 'crypto-js';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ChangePassword = ({ show, handleClose, title }: Props) => {
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string(),
    newPassword: Yup.string().required("New password is required"),
    newPasswordAgain: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null as any], "Passwords must match"),
  });

  const formik: any = useFormik({
    initialValues: {
      newPassword: "",
      newPasswordAgain: "",
    },
    validationSchema,
    onSubmit: async (values: any) => {


      let bodyParams = {
        newPassword: CryptoJS.SHA256(values?.newPassword ?? "").toString(),
        confirmPassword: CryptoJS.SHA256(values?.newPasswordAgain ?? "").toString(),
      };
      console.log(bodyParams);


      let response = await changeUserPassword(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Password changed Successfully!"
        );
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Entered password is incorrect");
      }
    },
  });

  const { mutateAsync: changeUserPassword, isLoading: isChanging } =
    useMutation("change-password", changePassword);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{title}</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              {/* <label className="form-label fw-bold">Current Password</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Current password"
                aria-label="current password"
                {...formik.getFieldProps("currentPassword")}
              />
              {formik.touched.currentPassword && formik.errors.currentPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.currentPassword}
                  </div>
                </div>
              )} */}
              <label className="form-label fw-bold">New Password</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="New password"
                aria-label="new password"
                {...formik.getFieldProps("newPassword")}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.newPassword}
                  </div>
                </div>
              )}
            </Row>
            <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">Confirm password</label>
                <input
                  placeholder="Confirm password"
                  aria-label="confirm password"
                  {...formik.getFieldProps("newPasswordAgain")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  type="text"
                  autoComplete="off"
                />
                {formik.touched.newPasswordAgain &&
                  formik.errors.newPasswordAgain && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.newPasswordAgain}
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
          </Container>
          <div className="w-100 d-flex p-5 justify-content-end mt-3">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isChanging ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default ChangePassword;
